import VueRouter, { Route } from 'vue-router';
import { startsWith } from 'lodash';

export function formatUrl(url?: string): string {
  return (
    '/' +
      (url &&
        url
          .replace(/^\//, '')
          .replace(/\/$/, '')
          .replace(/\?.*?$/, '')) || ''
  );
}

export function navigate(path: string, route: Route, router: VueRouter): void {
  const targetPath = formatUrl(path);
  if (
    !startsWith(route.path, targetPath) ||
    (route.path !== '/' && path === '')
  ) {
    router.push(formatUrl(path));
  }
}

const hexDigits = '0123456789abcdef';

export function uuid(): string {
  const s: any[] = [];
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010

  // tslint:disable-next-line: no-bitwise
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';
  return s.join('');
}

//格式化金额
export function getMoney(data: string) {
  if (!data) return '0';
  // 将数据分割，保留两位小数
  //data= data.toFixed(2)
  // 获取整数部分
  const intPart = Math.trunc(Number(data));
  // 整数部分处理，增加,
  const intPartFormat = intPart
    .toString()
    .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  // 预定义小数部分
  let floatPart = '';
  // 将数据分割为小数部分和整数部分
  const newArr = data.toString().split('.');
  if (newArr.length === 2) {
    // 有小数部分
    floatPart = newArr[1].toString(); // 取得小数部分
    return intPartFormat + '.' + floatPart;
  }

  return intPartFormat + floatPart;
}

/**
 * 深拷贝
 */
export function deepCopy(data) {
  if (typeof data !== 'object' || data === null) {
    return data;
  }
  let clone;
  if (Array.isArray(data)) {
    clone = [];
    for (let i = 0; i < data.length; i++) {
      clone[i] = deepCopy(data[i]);
    }
  } else {
    clone = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        clone[key] = deepCopy(data[key]);
      }
    }
  }
  return clone;
}

/**
 * 键值转换
 * @param val 当前数据值
 * @param options
 * @param keyName1 当前数据值对应的key(默认value)
 * @param keyName2 需要换取的key(默认label)
 */
export function keyValueConversion(
  val: string | number,
  options: any[],
  keyName1: string = 'value',
  keyName2: string = 'label'
) {
  return val ? options.filter(t => t[keyName1] === val)[0][keyName2] : '';
}

/**
 * 添加随机key
 * @param arr 当前数组
 */
export function addRandomKey(arr: any[]): any[] {
  let _arr = deepCopy(arr);
  return _arr.map(t => {
    return {
      ...t,
      key: new Date().getTime() + Math.floor(Math.random() * 1000) + 1
    };
  });
}

/**
 * 重新排序(升序)
 * @param arr 当前数组
 * @param key 排序字段
 */
export function reorderTable(arr: any[], key: string): any[] {
  let _arr = deepCopy(arr);
  _arr.sort((a, b) => a[key] - b[key]);
  return _arr;
}

/**
 * 从富文本中提取纯文本
 * @param richText 富文本
 * @return 纯文本
 */
export function extractPlainText(richText: string): string {
  if (!richText) return '';
  // 创建一个 DOMParser 实例
  const parser = new DOMParser();
  // 将富文本解析为 HTML 文档
  const doc = parser.parseFromString(richText, 'text/html');

  // 创建一个函数来递归遍历 DOM 树并提取文本节点
  const getTextFromNodes = node => {
    let text = '';
    if (node.nodeType === Node.TEXT_NODE) {
      // 如果是文本节点，则添加其文本内容
      text += node.textContent;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      // 如果是元素节点，则递归遍历其子节点
      node = node.firstChild;
      while (node) {
        text += getTextFromNodes(node);
        node = node.nextSibling;
      }
    }
    return text;
  };
  // 从文档的 body 中提取文本
  let plainText = getTextFromNodes(doc.body);
  // 去除多余的空白字符（可选）
  plainText = plainText.trim().replace(/\s+/g, ' ');
  return plainText;
}

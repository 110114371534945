import 'babel-polyfill';
import Vue, { CreateElement } from 'vue';
import App from './App.vue';
// import './registerServiceWorker'
import router from './router';
// import store from './store';
import ElementUI from 'element-ui';
import VueXss from 'vue-xss';
Vue.use(VueXss)
import '@/assets/common.scss';
import { Provider } from '@cds/common';
import { isEmpty } from 'lodash';

import { UserService } from '@/services/common/user.service';
// import { DevicePixelRatio } from '@/assets/devicePixelRatio';
import { I18nService, Lifecycle, defaultI18nPath, Locale } from '@cds/i18n';
import { DialogService } from './services/common/dialog.service';
// 注册全局自定义组件
import commonComponent from '@/components/custom';
import VueClipboard from 'vue-clipboard2';
import { PermissionService } from './services/common/permission.service';
import VueUeditorWrap from "vue-ueditor-wrap";

//ue编辑器引入
import '../public/ueditor/ueditor.config'
import '../public/ueditor/ueditor.all.js'
import '../public/ueditor/zh-CN.js'

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);
resizeRootFontSize();
window.addEventListener('resize', resizeRootFontSize);

function resizeRootFontSize() {
  document.documentElement.style['font-size' as any] =
    ((window.innerWidth * 100) / 1800).toFixed(2) + '%';
}
Vue.config.productionTip = false;
Vue.use(ElementUI);
const dialog = Provider(DialogService);

// 注册全局组件
Object.keys(commonComponent).forEach((key: string) => {
  Vue.use(commonComponent[key]);
});
Vue.component("vue-ueditor-wrap", VueUeditorWrap);

const i18nSvc: I18nService = Provider(I18nService);
const userService: UserService = Provider(UserService);
const permissionSvc: PermissionService = Provider(PermissionService);
permissionSvc.installDirective(Vue);
permissionSvc.installRouter(router);
const vueInstance = new Vue({
  router,
  render: (h: CreateElement) => {
    dialog.h = h;
    return h(App);
  }
});
const loading = vueInstance.$loading({
  lock: true,
  text: '加载中，请稍后...',
  spinner: 'el-icon-loading',
  customClass: 'init-loading',
  background: 'rgba(0, 0, 0, 0.7)'
});

function rootRender(isError?: boolean): void {
  i18nSvc.i18nPath = process.env.BASE_URL + defaultI18nPath.replace(/^\//, '');
  i18nSvc.currentLocaleId = Locale.zh_CN;
  i18nSvc.on(Lifecycle.loaded, async () => {
    if (!isEmpty(i18nSvc.i18nData)) {
      if (!isError) {
        await permissionSvc.findPermissionList();
      }
      vueInstance.$mount('#app');
      loading.close();
    }
  });
  i18nSvc.load();
}
userService.initUserAuth().then(() => rootRender()).catch(() => rootRender(true));

/**
 * @Author          : SuXiao xiao.su_ext@novartis.com
 * @Date            : 2024-06-22 13:56:43
 * @LastEditors     : SuXiao xiao.su_ext@novartis.com
 * @LastEditTime    : 2024-10-10 12:39:46
 * @FilePath        : \wecom-front-end-admin-portal\src\router\ability-assessment.router.ts
 * @Description     : [能力评估] 相关路由
 * @
 */
import { Role } from '@/model/schema';

const routes = [
  {
    path: '/typeManagement',
    name: 'typeManagement',
    component: () => import('../views/abilityAssessment/typeManagement/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '类型管理',
      titleEn: 'typeManagement'
    }
  },
  {
    path: '/levelInformationManagement',
    name: 'levelInformationManagement',
    component: () => import('../views/abilityAssessment/levelInformationManagement/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '等级信息管理',
      titleEn: 'levelInformationManagement'
    }
  },
  {
    path: '/basicInformationManagement',
    name: 'basicInformationManagement',
    component: () => import('../views/abilityAssessment/basicInformationManagement/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '基本信息管理',
      titleEn: 'basicInformationManagement'
    }
  },
  {
    path: '/curriculumManagement',
    name: 'curriculumManagement',
    component: () => import('../views/abilityAssessment/curriculumManagement/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '结果对应课程管理',
      titleEn: 'curriculumManagement'
    }
  },
  {
    path: '/feedbackReportManagement',
    name: 'feedbackReportManagement',
    component: () => import('../views/abilityAssessment/feedbackReportManagement/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '反馈报表管理',
      titleEn: 'feedbackReportManagement'
    }
  },
]

export default routes;











































import { CacheService, Inject } from '@cds/common';
import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/components/common/layout.vue';
import { UserapplicationService } from '@/services/common/userapplication-service';
// import { any } from 'core-js/fn/promise';

@Component({
  components: {
    Layout,
  }
})

export default class App extends Vue {
  public radio: any = 3;
  public showPage: boolean = false; //防止页面闪现
  public showDialog: boolean = false;
  public listData: any = [];
  public listMap: any = {
    "WECHAT_ADMIN": { // 默认内置
      img: require('@/assets/wechats.png'),
      color: 'upcolortwo',
      title: '企微'
    },
    "contentlib": {
      img: require('@/assets/document.png'),
      color: 'upcolor',
      title: '资料库'
    },
    "contentlib-crm": {
      img: require('@/assets/document.png'),
      color: 'upcolor',
      title: '资料库(CRM)'
    },
    "actalya_portal": {
      img: require('@/assets/act.png'),
      color: 'upcolortwo',
      title: 'ACTalya'
    },
    "rtw_portal": {
      img: require('@/assets/rtwwbf.png'),
      color: 'upcolor',
      title: '微拜访RTW'
    },
    "monitor_portal": {
      img: require('@/assets/monitor_portal.png'),
      color: 'upcolortwo',
      title: 'WeCom监控'
    },
  };

  @Inject(UserapplicationService) private userapplicationService!: UserapplicationService;
  @Inject(CacheService) private cacheSvc!: CacheService;
  private userInfo: any = this.cacheSvc.get('user');

  public async created(): Promise<void> {
    //如果页面刷新的话window.performance.navigation.type的值为1，如果值不是1就证明页面首次加载
    if (window.performance.navigation.type === 1) {
      console.log('[App.vue]created: 页面被刷新')
      this.showDialog = false;
      this.showPage = true;
    } else {
      // 这里可以写调用什么方法
      // console.log('页面首次被加载')
      this.showDialog = true;
      this.getSystemList();
    }
  }
  /* 点击图标 进入系统*/
  public async handleClickItem(item: any) {
    // let url = this.listData.find(item => item.name == val).url
    // let url = this.listData[0].url;
    if (item.name === 'WECHAT_ADMIN') {
      this.listData = [];
      return;
    }
    const url = item.url;
    if (url.substring(0, 4) == 'http') {
      window.location.href = url;
    } else {
      let links = `${location.origin}` + url
      window.location.href = links;
    }
  }
  /* 获取其他系统列表 */
  public async getSystemList(): Promise<void> {
    const data = await this.userapplicationService.systemList();
    console.log('[App]system列表 接口返回:', data)
    this.showPage = true;
    const dataList = data || [];
    if(dataList.length === 0) {
      this.listData = [];
      return;
    }
    const fullList = [
      { name: 'WECHAT_ADMIN', description: '', url: '/home'},
      // {description: "contentlib-crm", name: "contentlib", url: "/content-lib/?state=CL-ADMIN&code=fe0x2oi6buvcws9zf0d0za9f1qmw4j8d40va&scope=userinfo"},
      ...dataList,
    ].map(x => {
      const obj = this.listMap[x.name] || { img: require('@/assets/wechats.png'), color: 'upcolortwo', title: x.name };
      return {
        ...x,
        img: obj.img,
        color: obj.color,
        title: obj.title,
      }
    })
    this.listData = fullList;
    console.log(this.listData);
  }
}

/**
 * @Author          : SuXiao xiao.su_ext@novartis.com
 * @Date            : 2024-10-10 10:47:59
 * @LastEditors     : SuXiao xiao.su_ext@novartis.com
 * @LastEditTime    : 2024-10-10 10:50:27
 * @FilePath        : \wecom-front-end-admin-portal\src\router\wecom-video.router.ts
 * @Description     : [诺华视界] 相关路由
 * @
 */
import { Role } from '@/model/schema';

const routes = [
  {
    path: '/video_list',
    name: '视频列表',
    component: () => import('../views/wecom/videoList/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN],
      auth: true,
      title: '视频列表',
      titleEn: 'Wecom Video List'
    },
  },
  {
    path: '/wecomVideo/add',
    name: 'videoAdd',
    component: () => import('../views/wecom/video/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN],
      auth: true,
      title: '新增视频',
      titleEn: 'Wecom Video Add'
    },
  },
  {
    path: '/wecomVideo/:videoId/edit',
    name: 'videoEdit',
    component: () => import('../views/wecom/video/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN],
      auth: true,
      title: '编辑视频',
      titleEn: 'Wecom Video Edit'
    },
  },
  {
    path: '/wecomVideo/:videoId',
    name: 'videoDetail',
    component: () => import('../views/wecom/video/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN],
      auth: true,
      title: '视频详情',
      titleEn: 'Wecom Video Detail'
    },
  },
  {
    path: '/video_channel_management',
    name: 'channelManagement',
    component: () => import('@/views/wecom/channelManagement/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN],
      auth: true,
      title: '频道维护管理',
      titleEn: 'Wecom Channel Management'
    },
  },
  {
    path: '/video_statement',
    name: 'videoStatement',
    component: () => import('@/views/wecom/videoStatement/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN],
      auth: true,
      title: '行为数据查询',
      titleEn: 'WeCom Behavior Data Query'
    },
  },
]

export default routes;

/**
 * @Author          : yang, qilong
 * @Date            : 2023-04-17 11:50:08
 * @LastEditors     : SuXiao xiao.su_ext@novartis.com
 * @LastEditTime    : 2024-10-09 19:22:56
 * @FilePath        : \wecom-front-end-admin-portal\src\services\common\user.service.ts
 * @Description     :
 * @
 */
import { Inject, CacheService } from '@cds/common';
import { IUser } from './models/user.interface';
import querystring from 'qs';
import { HttpService, Mode } from './http.service';
import { isEmpty } from 'lodash';
const userInfoUrl = '/api/user';
const USER = 'user';
export class UserService {
  @Inject(HttpService) private http!: HttpService;
  @Inject(CacheService) private cache!: CacheService;
  private User: Dict<any>;

  constructor() {
    this.User = this.cache.get('user');
  }

  public async initUserAuth(): Promise<void> {
    const mode = process.env.NODE_ENV as Mode;
    if (mode === Mode.production) {
      const jwtToken = this.http.token;
      const { token } = querystring.parse(location.search.replace('?', ''));
      if (!isEmpty(token)) {
        this.http.mode = mode;
        this.http.token = 'Bearer ' + token;
        await this.initUserInfo(true);
        location.href = process.env.BASE_URL;
      } else if (isEmpty(jwtToken)) {
        console.log('process.env.VUE_APP_LOGIN_URL',process.env.VUE_APP_LOGIN_URL)
        location.href = process.env.VUE_APP_LOGIN_URL;
      }
    } else {
      const token = process.env.VUE_APP_API_TOKEN;
      if(!token) {
        alert('本地调试：缺少token! 需要在环境变量 [.env.development]的VUE_APP_API_TOKEN字段配置');
      }
      this.http.token = `Bearer ${process.env.VUE_APP_API_TOKEN}`;
      await this.initUserInfo(true);
    }
    localStorage.setItem('Token',this.http.token)
  }

  private async initUserInfo(refresh?: boolean): Promise<IUser> {
    const userInfoCash = this.cache.get(USER);
    let userInfo: any = {};
    if (!userInfoCash || refresh) {
      userInfo = await this.http.get(userInfoUrl);
      console.log('用户信息：',userInfo)
      if (userInfo) {
        this.cache.set(USER, userInfo);
      }
    }
    return userInfo;
  }
}
